// Inject CSS
require("./styles/main.scss");
require("./styles/datenschutz.scss");
require("./styles/impressum.scss");

// Devleopment Hot Module Replacement
if (module.hot) {
  module.hot.accept();
}

console.log("hello");

// Insert Image
// import bear from './assets/images/bear.jpg';
// const testImage = document.querySelector('.testImage');
// testImage.src = bear
